import React, { useState } from 'react';
import { API, Auth } from 'aws-amplify';

const LotteryNumberGenerator = () => {
  const [range, setRange] = useState({ start: 1, end: 45 });
  const [count, setCount] = useState(5);
  const [numbers, setNumbers] = useState<number[]>([]);

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const generateNumbers = () => {
    fetch(apiEndpoint + '/generate_numbers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        range_start: range.start,
        range_end: range.end,
        count: count
      })
    })
    .then(response => response.json())
    .then(data => {
      setNumbers(data.numbers);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    fetchData()
    .then(data => console.log(data))
    .catch(err => console.log(err));
  };
  
  async function fetchData() {
    let apiName = 'lotteryapi';        // replace with the name of your API as specified in Amplify
    let path = '/numbers';          // replace with the path you've set up for your endpoint
    let myInit = {
        headers: {
          'Content-Type': 'application/json'
        },               // OPTIONAL: add any headers if required
        // response: true,            // OPTIONAL: Return response data, default is false
        // queryStringParameters: {}, // OPTIONAL: pass query parameters if required
    };

    const response = await API.get(apiName, path, myInit);
    return response.data
  }

  Auth.currentAuthenticatedUser()
    .then(user => console.log(user))
    .catch(err => console.log(err));

  return (
    <div>
      <input type="number" value={range.start} onChange={e => setRange({ ...range, start: parseInt(e.target.value) })} />
      <input type="number" value={range.end} onChange={e => setRange({ ...range, end: parseInt(e.target.value) })} />
      <input type="number" value={count} onChange={e => setCount(parseInt(e.target.value))} />
      <button onClick={generateNumbers}>Generate</button>
      {numbers.map((number, index) => <div key={index}>{number}</div>)}
    </div>
  );
};

export default LotteryNumberGenerator;

